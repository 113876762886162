import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import {
  CustomSection,
  CustomSectionContent,
  CustomSectionStickyHeading,
  CustomSectionStickyHeadingInner,
  CustomSectionStickyItemsWrap,
} from '../../components/CustomSection';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import StickySidebar from '../../components/StickySidebar';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import GradesBlock from '../../components/GradesSection';
import CTA from '../../components/CTA';

import './index.scss';


class TestimonialsPage extends React.Component {
  state = {
    getTestimonialsFrom: 'all',
  };

  render() {
    const { getTestimonialsFrom } = this.state;

    return (
      <Layout
        pageWrapperClassName="testimonials-page"
        headerClassName="header_white-bg"
      >

        <Helmet
          title="Takeawayessay Reviews - Takeawayessay Review"
          meta={[
            {
              name: 'description',
              content: 'TakeAwayEssay Review is a reliable writing service with professional writers and guarantees that ensure the quality of your paper. According to TakeAwayEssay Reviews, customers are highly satisfied with the results and the 24/7 support.',
            },

            { property: 'og:title', content: 'Client Testimonials | TakeAwayEssay.com' },
            {
              property: 'og:description',
              content: 'We are popular among students of all levels, and their testimonials prove it. Read what our verified customers think about our writers, our prices, guarantees, and our service in general. Order your paper to get your desired grade.',
            },
            { property: 'og:site_name', content: 'Takeawayessay.com' },
            { property: 'og:type', content: 'website' },
            { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
          ]}
          link={[
            { rel: 'canonical', href: 'https://takeawayessay.com/testimonials.html' },
          ]}
        />

        <CustomSection className="custom-section_testimonials">
          <CustomSectionContent hasStickyElement>
            <CustomSectionStickyHeading className="testimonials__heading">
              <StickySidebar topSpacer={169}>
                <CustomSectionStickyHeadingInner className="testimonials__heading__inner">
                  <VerticalTextBlock
                    sectionClassName="testimonials"
                    dotsStyle="style-4"
                    text="Testimonials"
                  />
                  <div className="testimonials__heading__box">
                    <h1 className="testimonials__heading__h2">
                      TakeAwayEssay review: takeawayessay reviews
                    </h1>
                    <p className="testimonials__heading__paragraph">
                      Here’s what other clients think about our service.
                      Read their testimonials to learn how we helped
                      them improve their grades and writing.
                    </p>
                  </div>
                </CustomSectionStickyHeadingInner>
              </StickySidebar>
            </CustomSectionStickyHeading>
            <CustomSectionStickyItemsWrap>
              <Testimonials isOnlyContent nameOfPageArray={getTestimonialsFrom} />
            </CustomSectionStickyItemsWrap>
          </CustomSectionContent>
        </CustomSection>

        <GradesBlock />
        <Calculator />

        <CTA>
          Ready to become one of those who have
          <br />
          {' '}
          improved their grades thanks to our service?
        </CTA>

      </Layout>
    );
  }
}

export default TestimonialsPage;
